html, body {
  padding: 0 ;
  margin: 0;
  width: 100% ;
  height: 100% ;
}
body  {
  padding :  0 ; 
  margin : 0 ; 
  background-color:  #fff ; 
  
  height: 100% ;
}

.App {

  display: flex ;
  justify-content: center;
  align-items: center;
  padding-top: 50px ;
}

#root {
  width: 100%;
}